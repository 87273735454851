body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    transition: all .7s ease
}

button,
div,
p {
    box-sizing: border-box
}

ul {
    padding: 0;
    margin: 0
}

ul li {
    display: block
}

h1,
h2,
h3,
h4 {
    padding: 0;
    margin: 0;
    text-transform: uppercase
}

h1,
h2,
h3,
h4,
p {
    font-family: 'Century Gothic', sans-serif
}

a,
a:hover {
    text-decoration: none
}




body {
    background-image: url(../img/patterns.jpg);
}

.container {
    position: relative
}

.row {
    margin-right: 0;
    margin-left: 0
}

.header {
    height: 55px;
    width: 100%;
    background: linear-gradient(to bottom, #fff, #d4d4d4, #858585)
}

.logo {
    text-align: center;
    max-width: 300px;
    margin-left: 15%;
}

.logo h1 {
    font-size: 28px;
    color: #2f6f7d;
    text-shadow: 2px 2px 1px #fff;
    margin-top: 7px;
    transition: all .2s;
}

.logo h1:hover {
    color: #3fa4bb
}

.logo p {
    font-size: 10px;
    text-transform: uppercase;
    margin: 0
}

.menu ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px
}

.menu a {
    font-size: 15px;
    text-transform: uppercase;
    color: #000;
    transition: all .4s linear
}

.menu a:hover {
    color: #3fa4bb;
    text-shadow: 1px 1px 1px #fff;
    border-bottom: solid 1px #000;
    padding-bottom: 6px
}

.top-bar {
    display: none
}

.menu-toggle {
    float: right;
    width: 40px;
    height: 40px;
    margin-left: 20px;
    margin-top: 6px;
    display: block;
    position: relative
}

.menu-toggle span {
    display: block;
    background-color: #5fb7de;
    width: 24px;
    height: 3px;
    margin-top: -1.2px;
    text-shadow: none;
    color: transparent;
    position: absolute;
    right: 8px;
    top: 50%;
    bottom: auto;
    left: auto;
    transition: background .2s ease-in-out
}

.menu-toggle span::after,
.menu-toggle span::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: inherit;
    position: absolute;
    left: 0;
    transition-duration: .2s, .2s;
    transition-delay: .2s, 0s
}

.menu-toggle span::before {
    top: -8px;
    transition-property: top, transform, -webkit-transform
}

.menu-toggle span::after {
    bottom: -8px;
    transition-property: bottom, transform, -webkit-transform
}

.menu-toggle.is-clicked span {
    background-color: rgba(255, 0, 119, 0)
}

.menu-toggle.is-clicked span::after,
.menu-toggle.is-clicked span::before {
    background-color: #5fb7de;
    transition-delay: 0s, .2s
}

.menu-toggle.is-clicked span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.menu-toggle.is-clicked span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

#main-nav-wrap {
    display: block;
    width: 100%;
    font-family: Acumin-RPro, sans-serif;
    font-size: 1.5rem;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10
}

.main-navigation {
    background: 0 0;
    padding: 14px 20px 22px;
    margin: 0;
    width: 100%;
    height: auto;
    clear: both;
    display: none
}

.main-navigation>li {
    display: block;
    height: auto;
    text-align: left;
    padding: 0
}

.main-navigation li a {
    font-size: 16px;
    display: block;
    color: #a5a5a5;
    width: auto;
    padding: 15px 0;
    line-height: 10px;
    border: none;
    text-decoration: none;
    transition: all .5s ease
}

.main-navigation li a:hover {
    color: #5fb7de;
    margin-left: -.5rem
}

.top {
    position: relative
}

.top__img {
    position: relative;
    margin-top: 80px
}

.top__img img {
    width: 100%
}

.top__label p {
    font-size: 11px;
    position: absolute;
    right: -40px;
    bottom: 50px;
    transform: rotate(90deg);
    z-index: 3
}

.top__label a {
    color: #000
}

.top__label a:hover {
    color: #1b2fe8
}

.top__label span {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase
}

footer p {
    font-size: 12px;
    position: absolute;
    bottom: -55px;
    left: 47%
}

.contact-wrapper {
    max-width: 250px;
    margin: 75px 0 0 60px
}

.contact__text_item1 h4 {
    font-size: 14px;
    font-weight: 700;
    margin: 20px 0 10px
}

.contact__text_item1 p {
    font-size: 12px
}

.contact__text_item2 p {
    font-size: 13px;
    font-weight: 700;
    margin-top: 20px
}

.reg__form {
    position: relative;
    margin-top: 85px
}

.reg__form p {
    font-size: 14px;
    margin: 10px 0
}

.reg__form_mail p,
.reg__form_name p {
    display: inline-block
}

.reg__form_mail input,
.reg__form_name input {
    width: 195px;
    height: 30px;
    padding: 3px;
    border: solid 1px #1b1e21;
    border-radius: unset;
    margin-left: auto;
    float: right
}

.reg__form_mess textarea {
    width: 100%;
    background: 0 0;
    border: solid 1px #1b1e21;
    resize: none;
    color: #000;
    margin-bottom: 20px
}

.reg__form label span {
    font-size: 13px
}

.reg__form label input {
    margin-right: 15px
}

.reg__form_priv {
    font-size: 14px
}

.btn {
    background: #68cef8;
    margin-top: 20px;
    width: 100%
}

.btn:hover {
    cursor: pointer;
    color: #fff;
    background: #2fa8e8
}

.error {
    color: red
}

.contact__social ul {
    display: flex;
    justify-content: space-around;
    margin-top: 35px
}

.gallery__prew {
    position: relative;
    margin-top: 65px
}

.gallery__prew .col-6 {
    padding-right: 1px;
    padding-left: 1px;
}

.gallery__prew .col-md-3 {
    padding-right: 1px;
    padding-left: 1px;
}

.gallery__prew .col-lg-3 {
    padding-right: 1px;
    padding-left: 1px;
}

.gallery__prew_item {
    max-width: 250px;
    margin: 1px 0;
    padding: 0;
    z-index: auto;
    overflow: visible
}

.gallery__prew_item img {
    width: 100%;
    opacity: 1;
    transition: transform .5s;
    transform: perspective(1000px) translate3d(0, 0, 0)
}

.gallery__prew_item img:hover {
    transform: perspective(1000px) translate3d(0, 0, 25px);
    box-shadow: 1px 3px 20px rgba(0, 0, 0, .6);
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s
}

#galleria {
    height: 600px
}

.content {
    color: #777;
    font: 12px/1.4 "helvetica neue", arial, sans-serif;
    width: 620px;
    margin: 20px auto
}

.cred {
    margin-top: 20px;
    font-size: 11px
}

#overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    left: 0;
    top: 0;
    cursor: pointer
}

#thx {
    background: #2686b8;
    position: absolute;
    left: 50%;
    margin-left: -170px;
    top: 40%;
    padding: 30px;
    margin-top: -20px;
    color: #fff;
    text-transform: uppercase
}

